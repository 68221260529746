import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: ''
  },
  getters: {
  },
  mutations: {
    // SET_TOKEN(state, token) {
    //   state.token = token
    // }
  },
  actions: {
  },
  modules: {
    user
  }
})
