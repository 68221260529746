import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue')
      }
    ]
  },
  {
    path: '/consult',
    component: Layout,
    redirect: 'consult',
    children: [
      {
        path: '/consult',
        name: 'consult',
        component: () => import('@/views/consult/index.vue')
      }
    ]
  },
  {
    path: '/record',
    component: Layout,
    redirect: 'record',
    children: [
      {
        path: '/record',
        name: 'record',
        component: () => import('@/views/record/index.vue')
      }
    ]
  },
  {
    path: '/detail',
    component: Layout,
    redirect: 'detail',
    children: [
      {
        path: '/detail',
        name: 'detail',
        component: () => import('@/views/record/detail.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
