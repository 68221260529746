<template>
  <div>
    <div>
      <div class="container" separator="">
        <div
          class="back cursor-pointer"
          @click="onBack"
          v-if="title == '我的申请记录-详情'"
        >
          <i class="el-icon-arrow-left"></i> 返回列表
        </div>
        <div class="title" :to="{ path: '/' }">{{ title }}</div>
        <button
          @click="onChange(id)"
          class="change cursor-pointer"
          :style="
            title == '我的申请记录-详情' && checked == 3
              ? ''
              : 'visibility: hidden'
          "
        >
          修改资料
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'breadIndex',
  data () {
    return {}
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    checked: {
      type: Number
    },
    id: {
      type: Number
    },
    type: {
      type: Number
    }
  },
  created () {
  },
  methods: {
    onBack () {
      this.$router.go(-1)
    },
    onChange (id) {
      this.$router.push({
        path: (this.type == 1 ? '/home' : '/consult'),
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 67px;
  line-height: 67px;
  background: #fff;
  font-size: 16px;
  border-bottom: 1px solid #e2e2e2;
}
.title {
  color: #1e2b37;
  font-size: 16px;
  font-weight: bold;
}
.back {
  position: relative;
  right: 350px;
  font-size: 14px;
  font-weight: 500;
  color: #252e37;
}
.change {
  box-sizing: border-box;
  width: 80px;
  height: 34px;
  color: #fff;
  background: #1e469a;
  position: relative;
  left: 340px;
  top: 16px;
  border-radius: 4px;
}
</style>
