<template>
  <div>
    <div class="main">
      <div class="top">
        <div class="t_l">欢迎访问汕头市知识产权保护中心</div>
        <div class="t_r cursor-pointer" @click="onBack()">
          <img style="margin-right: 4px" src="@/assets/images/out.png" alt="" /> 退出登录
        </div>
      </div>
      <div class="container">
        <div class="tt">
          <img src="@/assets/images/topt.png" alt="" class="text" />
        </div>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<script>
export default {
  name: '',
  methods: {
    onBack () {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.top {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 14px;
  color: #333333;
  background: #f5f5f5;
  height: 37px;
  line-height: 37px;
}
.container {
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  background: url("~@/assets/images/main.png") no-repeat top left;
  background-size: 100% 100%;
}
.t_r {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #1e469a;
}
.tt {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.bottom {
  box-sizing: border-box;
  width: 100%;
  height: 12px;
  background: #1e469a;
}
</style>
