<template>
  <div>
    <div class="slidebar">
        <div
          class="nav cursor-pointer"
          v-for="item,index in navList"
          :key="index" @click="onGo(item, index)"
          :class="{ change:current == item.url }">
            {{ item.name }}
        </div>
    </div>
  </div>
</template>

<script>
import Store from './store.js'

export default {
  name: '',
  props: {
    current: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      navList: [
        { name: '纠纷调解申请', url: '/home' },
        { name: '侵权判定咨询', url: '/consult' },
        { name: '我的申请记录', url: '/record' }
      ]
    }
  },
  created () {
  },
  methods: {
    onGo (item, index) {
      this.$router.push({
        path: item.url
      })
      Store.$emit('fromBread', item.name)
    }
  }
}
</script>

<style scoped>
  .slidebar {
    margin-top: 30px;
    margin-right: 12px;
  }
  .nav {
    margin-bottom: 2px;
    width: 191px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    background: #fff;
  }
  .bar:hover {
    background: #1E469A;
  }
  .change {
    color: #fff;
    background: #1E469A;
  }
  .change::after {
    width: 22px;height: 22px;background: #000;
  }
  /* 三角 */
  .san {
    display: inline-block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: red;
  }
</style>
