<template>
  <div style="background: #f5f5f5" class="layout">
    <!-- 头部 -->
    <div class="top">
      <top></top>
    </div>
    <!-- 内容 -->
    <div class="main">
      <div class="container">
        <router-view />
      </div>
    </div>
    <!-- 底部 -->
    <div class="floor">
      <Floor></Floor>
    </div>
  </div>
</template>

<script>
import top from '@/layout/components/top.vue'
import slidebar from '@/layout/components/slidebar.vue'
import bread from '@/layout/components/bread.vue'
import Floor from './components/floor.vue'

export default {
  name: '',
  components: {
    bread,
    top,
    slidebar,
    Floor
  }
}
</script>

<style scoped>
.container {
  min-height: 650px;
}
</style>
