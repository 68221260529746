<template>
  <div>
    <div class="floor">
      版权所有：中国（汕头）知识产权保护中心 | 技术支持：中国专利信息中心 |
      粤ICP备2022044960号-1
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.floor {
  width: 100%;
  height: 100px;
  margin-top: 30px;
  background: #1e469a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
</style>